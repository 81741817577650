<template>
  <section
    id="menu-filter"
    class="filter"
    v-if="showFilters"
    :class="$route.name !== 'Home' || $route.name !== 'detailComponent' || $route.name === 'Movie' || $route.name === 'Tvshows' || $route.name === 'Music' || $route.name === 'Live' || $route.name === 'Bundles' ? backgroundTransparent : backgroundDark"
  >
    <div class="container">
      <div class="filter-content">
        <div class="filter-select">
          <span class="breadcrum" @click="screenRoute">{{ screenTitle }}</span>&nbsp;
          <div class="breadcrum" v-if="filteredGenre" @click="clearLanguageAndYear(filteredGenre)">
            <span>></span>
            {{ filteredGenre.toLowerCase() }}&nbsp;
          </div>
          <div class="breadcrum" v-if="filteredLang" @click="clearYear(filteredLang)">
            <span>></span>
            {{ filteredLang }}&nbsp;
          </div>
          <div class="breadcrum" v-if="filteredYear">
            <span>></span>
            {{ filteredYear }}
          </div>
        </div>
        <ul>
          <li>
            <select
              class="drop-layout genre-box"
              v-model="genre"
              id="genre-box-filter"
              @change="filterSelect($event)"
            >
              <option disabled value>Genre</option>
              <!-- <option value="genre-Action">Action</option>
              <option value="genre-Thriller">Thriller</option>-->
              <option
                v-for="(item, index) in genres"
                :key="index"
                :value="`genre-${item}`"
              >{{ item.toLowerCase()}}</option>
            </select>
          </li>
          <li>
            <select
              class="drop-layout lang-box"
              id="lang-box-filter"
              v-model="language"
              @change="filterSelect($event)"
            >
              <option disabled value>Language</option>
              <!-- <option>Kannada</option>
              <option>English</option>-->
              <option
                v-for="(item,i) in languages"
                :key="i"
                :value="`lang-${item.language}`"
              >{{item.language}}</option>
            </select>
          </li>
          <li>
            <select
              class="drop-layout year-box"
              v-model="year"
              id="year-box-filter"
              @change="filterSelect($event)"
            >
              <option disabled value>Year</option>
              <option
                v-for="(item,i) in releaseYear"
                :key="i"
                :value="`year-${item.year}`"
              >{{item.year}}</option>
            </select>
          </li>
        </ul>
        <ul class="filter-images">
          <li>
            <img
              v-if="!filteredGenre"
              id="small-genre"
              src="@/assets/icons/theater.svg"
              @click="showSmallGenre"
              alt
            />
            <img
              v-else
              id="small-genre"
              src="@/assets/icons/yellowGenreIcon.svg"
              @click="showSmallGenre"
              alt
            />
            <GenreDrop v-if="toggleSmallGenre" :genres="genres" @genreItem="smallGenreDeclare"></GenreDrop>
          </li>
          <li>
            <img
              v-if="!filteredLang"
              id="small-language"
              src="@/assets/icons/language.svg"
              @click="showSmallLanguage"
              alt
            />
            <img
              v-else
              id="small-language"
              src="@/assets/icons/yellowLangIcon.svg"
              @click="showSmallLanguage"
              alt
            />
            <LangDrop
              v-if="toggleSmallLanguage"
              :languages="languages"
              @languageItem="smallLanguageDeclare"
            ></LangDrop>
          </li>
          <li>
            <img
              v-if="!filteredYear"
              id="small-time"
              src="@/assets/icons/calendar-and-clock-time.svg"
              @click="showSmallTime"
              alt
            />
            <img
              v-else
              id="small-time"
              src="@/assets/icons/yellowCalendarIcon.svg"
              @click="showSmallTime"
              alt
            />
            <YearDrop v-if="toggleSmallTime" :years="releaseYear" @yearItem="smallYearDeclare"></YearDrop>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";

export default {
  name: "categoryFilter",
  props: {
    menu: {
      type: Object
    },
    filter: {
      type: String
    },
    bgColor: {
      type: String
    },
    routeData: {
      type: String
    }
  },
  data() {
    return {
      screenTitle: "",
      genre: "",
      filteredGenre: "",
      language: "",
      filteredLang: "",
      filteredLangArr: [],
      year: "",
      filteredYear: "",
      newFilter: "",
      genres: [],
      languages: [],
      releaseYear: [],
      size: null,
      backgroundTransparent: "filter-background-transparent",
      backgroundDark: "filter-background-dark",
      showFilters: true,
      toggleSmallGenre: false,
      toggleSmallLanguage: false,
      toggleSmallTime: false
    };
  },
  watch: {
    menu(val) {
      console.log("menu in watch", val);
      if (val) {
        this.screenTitle = val.id;
      }
      this.genre = "";
      this.filteredGenre = "";
      this.genres = [];
      this.genreList();
      this.languageList();
      this.language = "";
      this.filteredLang = "";
    },
    $route(to, from) {
      console.log("to", to);
      if (to.name === "detailComponent") {
        console.log("entered--------->");
        this.backgroundTransparent = this.backgroundDark;
      } else if (
        to.name === "Home" ||
        to.name === "Watchlist" ||
        to.name === "detail"
      ) {
        this.showFilters = false;
      } else {
        this.backgroundTransparent = "filter-background-transparent";
      }
    }
  },
  mounted() {
    this.listGenreData();
    // get the genre data
    // eventBus.$on("genre-items", response => {
    //   // console.log("genre-list-response", response);
    //   if (
    //     Object.keys(response).toString() ===
    //       this.menu.parameters.category.toString() &&
    //     this.menu.id !== "BUNDLE"
    //   ) {
    //     // console.log("Object values", Object.values(response)[0]);
    //     Object.values(response)[0].forEach(element => {
    //       // console.log("element", Object.keys(element).toString());
    //       this.genres.push(Object.keys(element).toString());
    //     });
    //     // console.log("genres", this.genres);
    //   } else if (this.menu.id === "BUNDLE") {
    //     Object.values(response)[0].forEach(element => {
    //       // console.log("element", Object.keys(element).toString());
    //       this.genres.push(Object.keys(element).toString());
    //     });
    //   }
    // });

    //get all i18n language data
    eventBus.$on("all-language", res => {
      this.languages = res.data.data;
      console.log("languages", this.languages);
      if (this.routeData && this.$route.name === "detailComponent") {
        this.languages.filter(element => {
          if (element.language === this.routeData) {
            this.filteredLang = this.routeData;
            this.filterSelect(`lang-${this.filteredLang}`);
          }
        });
      }
    });

    eventBus.$on("toggle-small-genre", state => {
      this.toggleSmallGenre = state;
    });

    eventBus.$on("toggle-small-language", state => {
      this.toggleSmallLanguage = state;
    });

    eventBus.$on("toggle-small-time", state => {
      this.toggleSmallTime = state;
    });

    //check if route params title is year
    if (this.routeData && this.$route.name === "detailComponent") {
      this.releaseYear.filter(el => {
        if (el.year == this.routeData) {
          this.filteredYear = el.year;
          // this.createFilterParameters();
          this.filterSelect(`year-${this.filteredYear}`);
        }
      });
    }
  },
  created() {
    this.genreList();
    this.languageList();
    this.yearRange();
    // console.log("menu in category filter", this.menu);
    if (this.menu) {
      this.screenTitle = this.menu.id;
    }
    if (this.bgColor) {
      this.backgroundTransparent = this.bgColor;
    }
  },
  methods: {
    screenRoute() {
      this.$router.push({ name: "Movie" });
      this.genre = "";
      this.filteredGenre = "";
      this.genres = [];
      // this.genreList();
      // this.languageList();
      this.language = "";
      this.filteredLang = "";
      this.filteredYear = "";
      document.getElementById("genre-box-filter").style.color = "#fff";
      document.getElementById("lang-box-filter").style.color = "#fff";
      document.getElementById("year-box-filter").style.color = "#fff";
    },

    //genre list call for different screens
    genreList() {
      eventBus.$emit("genre-list");
    },

    //genre response and check for route param is genre
    listGenreData() {
      eventBus.$on("genre-items", response => {
        console.log("genre response", response);
        this.genres = [];
        if (Object.keys(response).toString() === "MOVIE") {
          Object.values(response)[0].forEach(element => {
            this.genres.push(Object.keys(element).toString());
          });
          console.log("this.genres", this.genres);
          if (this.routeData && this.$route.name === "detailComponent") {
            this.genres.filter(el => {
              if (
                el.toLowerCase() === this.routeData &&
                this.$route.name === "detailComponent"
              ) {
                this.filteredGenre = this.routeData;
                // this.createFilterParameters();
                this.filterSelect(`genre-${this.filteredGenre}`);
              }
            });
          }
        }
      });
    },

    //i18n language call for objectlanguage
    languageList() {
      eventBus.$emit("language-list");
    },

    //defining production year for filter
    yearRange() {
      let d = new Date();
      this.releaseYear = [];
      for (let i = 1900; i <= d.getFullYear(); i++) {
        this.releaseYear.push({ year: i });
      }
      return this.releaseYear;
    },

    //all filter functionalities
    filterSelect(val) {
      let targetVal = this.routeData ? val : val.target.value;
      let findDash = targetVal.indexOf("-");
      let slicedVal = targetVal.slice(0, findDash);

      if (slicedVal == "genre") {
        this.filteredGenre = targetVal.slice(findDash + 1, targetVal.length);
        document.getElementById("genre-box-filter").style.color = "#ff9600";
      } else if (slicedVal == "lang") {
        this.filteredLangArr = [];
        this.filteredLang = targetVal.slice(findDash + 1, targetVal.length);
        this.filteredLangArr.push(this.filteredLang);
        document.getElementById("lang-box-filter").style.color = "#ff9600";
      } else if (slicedVal == "year") {
        this.filteredYear = targetVal.slice(findDash + 1, targetVal.length);
        document.getElementById("year-box-filter").style.color = "#ff9600";
      }
      this.createFilterParameters();
    },
    createFilterParameters(ranData) {
      let payload = {
        mainObj: {
          genre: this.filteredGenre
            ? this.filteredGenre
            : ranData
            ? ranData
            : "",
          objectlanguage: this.filteredLang ? this.filteredLangArr : "",
          productionyear: this.filteredYear ? this.filteredYear : "",
          category: this.menu.parameters.category,
          objecttype: this.menu.parameters.objecttype,
          pagesize: 30
        },
        checkObj: true,
        displayType: "PORTRAIT"
      };
      console.log("request content payload", payload);
      let category = this.menu.parameters.category[0];
      let title = this.filteredGenre
        ? this.filteredGenre.toLowerCase()
        : this.filteredLang
        ? this.filteredLang.toLowerCase()
        : this.filteredYear;
      //check and go to detail component
      if (this.$route.name !== "detailComponent") {
        this.$router.push({
          name: "detailComponent",
          params: { category, title, payload }
        });
      } else {
        eventBus.$emit("send-to-detail", payload);
      }
    },

    //scrolling effect for menuFilter.
    scrollingEffect() {
      if (this.menuFilter) {
        window.onscroll = () => {
          if (window.pageYOffset > 520) {
            document.getElementById("menu-filter").style.backgroundColor =
              "#1D1D1D";
          }
          if (window.pageYOffset < 520) {
            document.getElementById("menu-filter").style.background =
              "linear-gradient(180deg, #000000 0%, rgba(26, 26, 26, 0.1) 100%)";
          }
        };
      }
    },

    clearLanguageAndYear(val) {
      console.log("val", val);
      if (this.filteredLang) {
        this.filteredLang = null;
        this.language = "";
        this.filteredLangArr = [];
        document.getElementById("lang-box-filter").style.color = "#fff";
      }
      if (this.filteredYear) {
        this.filteredYear = null;
        this.year = "";
        document.getElementById("year-box-filter").style.color = "#fff";
      }
      this.createFilterParameters(val);
    },

    clearYear(val) {
      if (this.filteredYear) {
        this.filteredYear = null;
        this.year = "";
        document.getElementById("year-box-filter").style.color = "#fff";
      }

      let payload = {
        mainObj: {
          genre: this.filteredGenre ? this.filteredGenre : "",
          category: this.menu.parameters.category,
          objecttype: this.menu.parameters.objecttype,
          objectlanguage: this.filteredLang ? this.filteredLangArr : "",
          productionyear: this.filteredYear ? this.filteredYear : ""
        },
        checkObj: true,
        displayType: "PORTRAIT"
      };

      eventBus.$emit("send-to-detail", payload);
    },

    showSmallGenre() {
      this.toggleSmallGenre = !this.toggleSmallGenre;
    },

    smallGenreDeclare(data) {
      this.filteredGenre = data;
      this.createFilterParameters();
    },

    showSmallLanguage() {
      this.toggleSmallLanguage = !this.toggleSmallLanguage;
    },

    smallLanguageDeclare(data) {
      this.filteredLang = data;
      this.createFilterParameters();
    },

    showSmallTime() {
      this.toggleSmallTime = !this.toggleSmallTime;
    },

    smallYearDeclare(data) {
      this.filteredYear = data;
      this.createFilterParameters();
    }
  },
  components: {
    GenreDrop: () =>
      import(
        /* webpackChunkName: "genreDrop" */ "@/components/MobileTemplates/GenreDropdown.vue"
      ),
    LangDrop: () =>
      import(
        /* webpackChunkName: "LangDrop" */ "@/components/MobileTemplates/LanguageDropdown.vue"
      ),
    YearDrop: () =>
      import(
        /* webpackChunkName: "yearDrop" */ "@/components/MobileTemplates/YearDropdown.vue"
      )
  },
  mixins: [Utility]
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./categoryFilter.scss"
</style>
